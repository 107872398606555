.list-card {
  padding-top: 100px;
  margin: 0 30px 0 15px;
}

.list-item {
  display: flex;
  justify-content: space-between;

  padding-bottom: 110px;
  padding-top: 15px;
  border-top: 1px solid black;
}

.item-list-pic {
  height: 93px;
  width: 77px;
}

.item-list-title {
  font-size: 35px;
  margin-left: 10px;
  font-family: 'Instrument Serif', sans-serif;
}

.item-list-date {
  font-size: 22px;
  margin-left: 5px;
  font-family: 'Instrument Serif', sans-serif;
}

.content {
  display: flex;
  justify-content: space-between;
}

.list-circle-icon {
  width: 18px;
  height: 18px;
  align-self: center;
}

@media only screen and (max-width: 500px) {
  .item-list-title {
    font-size: 30px;
  }

  .list-item {
    padding-bottom: 60px;
  }

  .item-list-date {
    font-size: 18px;
  }
}

@media only screen and (max-width: 353px) {
  .item-list-title {
    font-size: 26px;
  }

  .list-item {
    padding-bottom: 50px;
  }

  .item-list-date {
    font-size: 14px;
  }
}
