.invert-color {
  mix-blend-mode: difference;
  color: white;
  font-family: 'Instrument Serif', sans-serif;
}

.mainLogo {
  max-width: 90%;
}

@media only screen and (max-width: 1200px) {
  .mainLogo {
    max-width: 130%;
  }
}

@media only screen and (max-width: 700px) {
  .mainLogo {
    max-width: 170%;
  }
}

@media only screen and (max-width: 500px) {
  .mainLogo {
    max-width: 220%;
  }
}
