.header {
  position: absolute;
  width: 100%;
}

.header .main-nav {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  color: white;
  padding-top: 212px;
  padding-left: 30px;
  padding-right: 30px;
  transition: transform 1.5s ease-in-out, opacity 1.5s ease-in-out;
  transform: translateY(100%);
  z-index: 1;
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
}

.nav-open .main-nav {
  opacity: 1;
  pointer-events: auto;
  visibility: visible;
  transform: translateY(0);
}

.nav-close .main-nav {
  transform: translateY(100%);
  opacity: 0;
}

.nav-close {
  pointer-events: none;
  visibility: hidden;
  transition-delay: 1.5s; /* Delays hiding until the animation completes */
}

.nav-btn {
  font-size: 24px;
  position: fixed;
  top: 30px;
  right: 30px;
  z-index: 2;
  background: none;
  border: none;
  cursor: pointer;
  color: inherit;
  transition: color 0.3s ease-in-out;
  font-family: 'Instrument Serif', sans-serif;
  mix-blend-mode: difference;
  color: white;
}

.color-white {
  color: #fff;
}

.main-nav-list {
  display: flex;
  flex-direction: row;
  gap: 30px;
  flex-wrap: wrap;
  font-family: 'Instrument Serif', sans-serif;
}

.main-nav-list-item {
  font-size: 30px;
  opacity: 0;
  transform: translateY(50px);
  animation: none;
}

.nav-open .main-nav-list-item {
  animation: slideUp 1.5s ease-in-out forwards; /* Text still appears slowly */
}

.nav-open .main-nav-list-item:nth-child(1) {
  animation-delay: 0.4s;
}

.nav-open .main-nav-list-item:nth-child(2) {
  animation-delay: 0.6s;
}

.nav-open .main-nav-list-item:nth-child(3) {
  animation-delay: 0.8s;
}

.nav-open .main-nav-list-item:nth-child(4) {
  animation-delay: 1s;
}

@keyframes slideUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.main-nav-list-item-link {
  display: flex;
  gap: 5px;
  text-decoration: none;
  color: inherit;
  transition: color 0.3s ease;
}

.main-nav-list-item-link-number {
  font-size: 15px;
}

.back-btn {
  width: 30px;
  height: 30px;
  position: fixed;
  top: 30px;
  left: 30px;
  z-index: 2;
}

.logo {
  width: 37px;

  font-size: 24px;
  position: fixed;
  top: 30px;
  left: 30px;
  z-index: 2;
  background: none;
  border: none;
  cursor: pointer;
  color: inherit;
  transition: color 0.3s ease-in-out;
  font-family: 'Instrument Serif', sans-serif;
  mix-blend-mode: difference;
  color: white;
}

@media only screen and (max-width: 700px) {
  .header .main-nav {
    padding-top: 100px;
  }
}

@media only screen and (max-width: 525px) {
  .main-nav-list {
    flex-direction: column;
  }
}

@media only screen and (max-width: 378px) {
  .main-nav-list-item {
    font-size: 25px;
  }
}

@media only screen and (max-width: 333px) {
  .main-nav-list-item {
    font-size: 20px;
  }
}
