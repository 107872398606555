.address-list {
  font-family: 'Instrument Serif', sans-serif;
  flex-wrap: wrap;
}

.footer-logo img {
  width: 100vw;
}

.footer-logo {
  position: absolute;
  bottom: 0px;
  transform: translateY(51.5%);
}

@media only screen and (max-width: 800px) {
  .address-list {
    gap: 95px;
  }

  .footer-address {
    padding-top: 40px;
  }
}

@media only screen and (max-width: 690px) {
  .address-list {
    row-gap: 40px;
  }

  /* .footer-logo {
    width: 400px;
  } */
}

@media only screen and (max-width: 527px) {
  .address-list {
    flex-direction: column;
  }
}

/* @media only screen and (max-width: 470px) {
  .footer-logo {
    width: 300px;
  }
} */

/* @media only screen and (max-width: 333px) {
  .footer-logo {
    width: 250px;
  }
} */

/* @media only screen and (max-height: 659px) {
  .footer-logo {
    width: 400px;
  }
} */

@media only screen and (max-height: 572px) {
  /* .footer-logo {
    width: 300px;
  } */

  .footer-address {
    padding-top: 40px;
  }
}

@media only screen and (max-height: 424px) {
  /* .footer-logo {
    width: 170px;
  } */

  .address-list {
    row-gap: 20px;
  }
}
