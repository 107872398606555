.item-section {
  position: relative;
  padding-bottom: 60px;
}

.item-header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 10;
  padding: 30px 30px 0 30px;
  font-family: 'Instrument Serif', sans-serif;
}

.item-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.item-headline-container {
  display: flex;
  gap: 10px;
}

.item-headline-direction {
  font-size: 22px;
  align-self: self-end;
  font-family: 'Instrument Serif', sans-serif;
}

.item-headline-title,
.section-close-link {
  font-size: 40px;
}

.item-text {
  margin-top: 112px;
  font-size: 23px;
  line-height: 30px;
  padding: 0 30px 60px 30px;
  font-family: 'Instrument Serif', sans-serif;
}

.item-pic-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.item-pic-list-element:nth-child(odd) {
  grid-column: 1; /* Odd elements in the first column */
}

.item-pic-list-element:nth-child(even) {
  grid-column: 2; /* Even elements in the second column */
}

@media only screen and (max-width: 1000px) {
  .item-headline-direction {
    font-size: 20px;
  }

  .item-headline-title,
  .section-close-link {
    font-size: 35px;
  }
}

@media only screen and (max-width: 680px) {
  .item-text {
    font-size: 25px;
    line-height: 35px;
  }
}

@media only screen and (max-width: 400px) {
  .item-headline-direction {
    font-size: 15px;
  }

  .item-headline-title,
  .section-close-link {
    font-size: 26px;
  }
}
